// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Use mock data instead of live data from the api
  useMock: false,
  // Use the admin with mock data, expirimental
  useMockAdmin: false,
  auth: {
    domain: 'dev-login.rouseservices.com',
    clientId: 'sPOmpoETtEOlWTEiIq5Fw5LcBgVoB0Oi',
    authorizationParams: {
      redirect_uri: `${window.location.protocol}//${window.location.host}/admin`,
      audience: 'https://api.rouseservices.com',
    },
  },
  client: '',
  data: {
    api: '/api',
  },
  image: {
    api: "https://media.develop.rouseservices.com",
    legacyApi: "https://imageserver.develop.rouseservices.com/ImageProcessor/get/getimage.aspx"
  },
  /**
   * Log Levels:
   * All = 0,
   * Debug = 1,
   * Info = 2,
   * Warn = 3,
   * Error = 4,
   * Fatal = 5,
   * Off = 6
   */
  logLevel: 0,
  recaptcha: {
    siteKey: '6LflRAMpAAAAAOIIzfexlwMtoJTW_z6OnXTln2MZ',
  },
  sentry: {
    environment: 'develop',
    traceSampleRate: 0,
  },
  clientsBlockRobots: [],
  firebaseEnv: 'develop',
  firebaseConfig: {
    apiKey: "AIzaSyBwo3dy74VgNngJo29xcPXzFuEdQqbr3UM",
    authDomain: "rfm-dev-1e0721.firebaseapp.com",
    databaseURL: "https://rfm-dev-1e0721.firebaseio.com",
    projectId: "rfm-dev-1e0721",
    storageBucket: "rfm-dev-1e0721.appspot.com",
    messagingSenderId: "951920164207",
    appId: "1:951920164207:web:1643dbef2b892997a97c9a",
    measurementId: "G-VL4W2YF93L"
  },
  googleAnalytics: {
    trackingId: 'G-HQBZT20VZ0',
  },
  excludedTLDValidationDomains: ['rouseservices.com', 'rousewebshops.com'],
  heap: {
    appId: '2557030504',
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'  // Included with Angular CLI.
